<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">Add Permission</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form lazy-validation autocomplete="off">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  outlined
                  dense
                  label="Permission"
                  v-model="permission_name"
                ></v-text-field>
                <span class="text-danger" v-if="$v.permission_name.$error"
                  >Permission name is required</span
                >
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="checkIsAccessible('permission', 'create')"
          color="primary"
          @click="createPermission"
          :loading="isBusy"
        >
          Save
        </v-btn>
        <v-btn color="red darken-1" dark @click="closeDialog"> Cancel </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PermissionService from "@/service/user/permission/PermissionService";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

const permissionService = new PermissionService();

export default {
  name: "CreateAndUpdate",
  mixins: [validationMixin],
  validations: {
    permission_name: { required }
  },
  data() {
    return {
      dialog: false,
      isBusy: false,
      permission_name: null
    };
  },
  methods: {
    createPermission() {
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        permissionService
          .store({ name: this.permission_name })
          .then(response => {
            if (response.data.status === "OK") {
              this.closeDialog();
              this.permission_name = null;
              this.$emit("update_list");
            }
          });
      }
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    }
  }
};
</script>

<style scoped></style>
