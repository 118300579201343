import ApiService from "../../../core/services/api.service";

const apiService = ApiService;

export default class PermissionService {
  #api = null;

  constructor() {
    this.#api = `admin/permission`;
  }

  paginate(index = null) {
    let url = `${this.#api}`;
    if (index) url = url + "?page=" + index;
    return apiService.get(url);
  }

  all() {
    let url = `${this.#api}/all/permissions`;
    return apiService.get(url);
  }

  update(id, data) {
    let url = `${this.#api}/${id}`;
    return apiService.put(url, data);
  }

  store(data) {
    let url = `${this.#api}/create`;
    return apiService.post(url, data);
  }

  show(id) {
    let url = `${this.#api}/${id}`;
    return apiService.get(url);
  }

  delete(id) {
    let url = `${this.#api}/${id}`;
    return apiService.delete(url);
  }

  getGroupedPermission() {
    let url = `${this.#api}/by/group`;
    return apiService.get(url);
  }
}
