<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
          class="wizard wizard-2"
          id="kt_wizard_v2"
          data-wizard-state="step-first"
          data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row">
              <div class="col-4">
                <h4>
                  <router-link :to="{ name: 'role' }">
                    <i class="fas fa-arrow-left mr-2"></i>
                  </router-link>
                  {{ role.name_text }}'s Permissions
                </h4>
              </div>
              <div
                class="col-8 text-right"
                v-if="checkIsAccessible('permission', 'edit')"
              >
                <button
                  @click="setDefaultPermission()"
                  v-if="role.name !== 'admin'"
                  class="btn btn-primary ml-2"
                >
                  <i class="fas fa-key"></i>
                  Set/Reset Default Permission
                </button>
                <button
                  @click="showPermissionModal()"
                  class="btn btn-primary ml-2"
                >
                  Add New Permission
                </button>
                <button
                  @click="showPermissionList()"
                  class="btn btn-primary ml-2"
                >
                  <i class="fas fa-list"></i>
                  Permission List
                </button>
              </div>
              <div class="table-responsive mt-2">
                <table class="table">
                  <thead class="text-left">
                    <th>{{ role.name ? role.name.toUpperCase() : null }}</th>
                    <th colspan="3">
                      ({{
                        role_permissions && role_permissions.length > 0
                          ? role_permissions.length
                          : 0
                      }}) Assigned Permissions
                    </th>
                    <th>Permission</th>
                    <th></th>
                  </thead>
                  <tbody class="text-left">
                    <tr>
                      <th>
                        <b-form-checkbox
                          v-if="role.name !== 'admin'"
                          id="checkbox-1"
                          v-model="status"
                          @change="toggleRolePermission()"
                          name="checkbox-1"
                        >
                          Select All
                        </b-form-checkbox>
                      </th>
                      <th class="text-center">Create</th>
                      <th class="text-center">Delete</th>
                      <th class="text-center">Edit</th>
                      <th class="text-center">List</th>
                      <th class="text-center">Show</th>
                    </tr>
                    <tr v-for="(permissionList, i) of permissionLists" :key="i">
                      <th>
                        {{ i }}
                      </th>
                      <td
                        class="text-center"
                        v-for="(permission, j) of permissionList"
                        :key="j"
                      >
                        <label v-if="i == 'other'">{{ permission }}</label>
                        <b-form-checkbox
                          @change="assignPermission()"
                          switch
                          size="lg"
                          v-model="selected_permissions"
                          :value="permission"
                          :disabled="role.name === 'admin'"
                        ></b-form-checkbox>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <create-and-update
      ref="create-permission"
      @update_list="updatePermissionList"
    ></create-and-update>
  </v-app>
</template>
<script>
import CreateAndUpdate from "./CreateAndUpdate";
import { mapGetters } from "vuex";
import RoleService from "@/service/user/role/RoleService";
import PermissionService from "@/service/user/permission/PermissionService";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

const roleService = new RoleService();
const permissionService = new PermissionService();

export default {
  name: "users",
  components: { CreateAndUpdate },
  data() {
    return {
      selected_permissions: [],
      role_permissions: [],
      permissionLists: [],
      role: {},
      total: null,
      status: false,
      perPage: null,
      total_available_permissions: 0
    };
  },
  computed: {
    role_id() {
      return this.$route.params.id;
    },
    ...mapGetters(["currentUser"])
  },
  mounted() {
    this.rolePermissions();
    this.getRole();
    this.getPermissions();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Role & Permission" },
      { title: "Manage Permission" }
    ]);
  },
  methods: {
    rolePermissions() {
      roleService.rolePermissions(this.role_id).then(response => {
        this.role_permissions = response.data;
        this.selected_permissions = [];
        if (this.role_permissions && this.role_permissions.length > 0) {
          this.role_permissions.forEach(element => {
            this.selected_permissions.push(element);
          });
        }
      });
    },
    getRole() {
      roleService.show(this.role_id).then(response => {
        this.role = response.data.role;
      });
    },
    getPermissions() {
      permissionService.getGroupedPermission().then(response => {
        this.permissionLists = response.data.permission_list;
        this.total_available_permissions = response.data.total_permissions;
        if (
          this.selected_permissions.length === this.total_available_permissions
        ) {
          this.status = true;
        }
      });
    },
    showModal(modal) {
      this.$refs[modal].show();
    },
    hideModal(modal) {
      this.teacher = [];
      this.$refs[modal].hide();
    },
    setDefaultPermission() {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            roleService
              .setDefault(this.role_id)
              .then(response => {
                if (response.data.status === "OK") {
                  this.$snotify.success("Assigned successfully");
                  this.rolePermissions();
                }
              })
              .catch(() => {});
          }
        }
      });
    },

    deleteRolePermission(permission) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            roleService
              .removePermission(this.role_id, { permissions: [permission] })
              .then(response => {
                if (response.data.status === "OK") {
                  this.$snotify.success("Deleted successfully");
                  this.userRoles();
                }
              })
              .catch(() => {});
          }
        }
      });
    },
    assignPermission() {
      roleService
        .assignPermission(this.role_id, {
          permissions: this.selected_permissions
        })
        .then(() => {
          this.user_roles = [];
          this.$snotify.success("Assigned Successfully");
          this.rolePermissions();
        });
    },
    showPermissionModal() {
      this.$refs["create-permission"].openDialog();
    },
    showPermissionList() {
      this.$router.push({ name: "permission" });
    },
    updatePermissionList() {
      this.getPermissions();
      this.getRole();
    },
    toggleRolePermission() {
      if (!this.status) {
        this.selected_permissions = [];
        this.assignPermission();
      } else {
        permissionService.all().then(response => {
          let permissions = response.data.data;
          permissions.forEach(permission => {
            this.selected_permissions.push(permission.name);
          });
          this.assignPermission();
        });
      }
    }
  }
};
</script>
